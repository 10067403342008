import React from 'react';
import styles from './Login.module.css';
import Logo from '../images/Hai-Ciso .png';
import LogoBorder from '../images/logo-border.svg';
import emailicon from '../images/email-icon.svg';
import pwdicon from '../images/password-icon.svg';
import {Row,Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import axios from 'axios';

class Login extends React.Component {

    state = {
        email: "",
        password: "",
    }

    changeInputs = (key,value)=>{
      this.setState({
          [key]:value
      })
      
    }

    onSubmit =  ()=>{
        if(this.state.email.length > 0 && this.state.password.length > 0){
               axios.post("https://psp01.herokuapp.com/auth/login",{email:this.state.email,password:this.state.password}).then(res=>{
                 localStorage.setItem("token","d51de828-d488-442a-9362-68615e8b6785")
                 this.props.history.push("/dashboard")
               })
        }
    }

    render(){
       return (
            <div className={styles.maindiv}>
                <div className={styles.maininner_div}>
                    {/* <div className={styles.ellipse}></div> */}
                <div class="row">
                        <div class="col-md-6 text-right">
                        <Link to="/login"><button className={styles.signin_btn} >Sign In</button></Link>
                        </div>
                        <div class="col-md-6 text-left">
                        <Link to="/signup"><button className={styles.signup_btn}>Sign Up</button></Link>
                        </div>
                    </div>
                    
                    <Row>
                        <Col md={6} className={styles.logo_div}>
                            <div className={styles.logo_section}>
                                <img alt="image" src={LogoBorder} className={styles.logo_border}/>
                                <img alt="image" src={Logo} className={styles.signin_logo}/>
                            </div>
                            
                        </Col>
                        <Col md={6} className={styles.form_div}>
                            <form onSubmit={(event)=>event.preventDefault()} className={styles.signin_form}>
                                
                                <div className={styles.mail_div}>
                                    <img src={emailicon} className={styles.input_icon}/>
                                    <input onChange={(event)=>this.changeInputs("email",event.target.value)} type="text" className={styles.email_inputs} placeholder="Email"/>
                                </div>
                                <div className={styles.pass_div}>
                                    <img alt="image" src={pwdicon} className={styles.input_icon}/>
                                    <input onChange={(event)=>this.changeInputs("password",event.target.value)} type="password" className={styles.password_inputs} placeholder="Password"/>
                                </div>
                                <div style={{marginTop: "28px"}}>
                                    <div class="text-left" style={{
                                        width: "50%",
                                        float: "left",
                                        color: "#fff"
                                    }}>
                                        <input type="checkbox"/> Remember me
                                    </div>
                                    <div class="text-right" style={{
                                        width: "50%",
                                        marginLeft: "50%",
                                        color: "#FF4B00"
                                    }}>
                                        <p>Forgot Password ?</p>
                                    </div>
                                </div>
                                <div>
                                    <button onClick={this.onSubmit} className={styles.submit_btn}>Sign In</button>
                                </div>
                            </form>
                            
                        </Col>
                    </Row>
                </div>
                
                
            </div>
        )
       
    }
}

export default Login;