import React from  "react";
import {Link} from "react-router-dom";
import logo from "../images/Hai-Ciso .png"

class Landing extends React.Component{
    render() {
       return (
        <div className="container-fluid">
            <nav className="text-light d-flex justify-content-between">
                <img height="50px" src={logo} alt="logo"/>
                <div className="d-flex">
                <Link className="text-light p-3" to="/dashboard">Dashboard</Link>
                <Link className="text-light p-3"  to="/login"> Login </Link>
                <Link className="text-light p-3"  to="/signup"> Signup </Link>
                <Link  className="text-light p-3" to="/enquiry"> Enquiry </Link>
                </div>
            </nav>

        </div>
       )
    }
}

export default Landing