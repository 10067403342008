import React from 'react';
import styles from './Signup.module.css';
import Logo from '../images/Hai-Ciso .png';
import LogoBorder from '../images/logo-border.svg';
import usericon from '../images/user-icon.svg';
import emailicon from '../images/email-icon.svg';
import pwdicon from '../images/password-icon.svg';
import {Row, Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';

const SignUp = () => {
    return (
        <div className={styles.maindiv}>
            <div className={styles.maininner_div}>
            <div class="row">
                    <div class="col-md-6 text-right">
                    <Link to="/login"><button className={styles.signin_btn}>Sign In</button></Link>
                    </div>
                    <div class="col-md-6 text-left">
                    <Link to="/signup"><button className={styles.signup_btn}>Sign Up</button></Link>
                    </div>
                </div>
                
                <Row>
                    <Col md={6} className={styles.logo_div}>
                        <div className={styles.logo_section}>
                            <img alt="image" src={LogoBorder} className={styles.logo_border}/>
                            <img alt="image" src={Logo} className={styles.signin_logo}/>
                        </div>
                        
                    </Col>
                    <Col md={6} className={styles.form_div}>
                        <form className={styles.signin_form}>
                            <div className={styles.uname_div}>
                                <img alt="image" src={usericon} className={styles.input_icon}/>
                                <input type="text" className={styles.uname_inputs} placeholder="Username"/>
                            </div>
                            <div className={styles.mail_div}>
                                <img alt="image" src={emailicon} className={styles.input_icon}/>
                                <input type="text" className={styles.email_inputs} placeholder="Email"/>
                            </div>
                            <div className={styles.pass_div}>
                                <img  alt="image" src={pwdicon} className={styles.input_icon}/>
                                <input type="text" className={styles.password_inputs} placeholder="Password"/>
                            </div>
                            
                            <div>
                                <button className={styles.submit_btn}>Sign Up</button>
                            </div>
                        </form>
                        
                    </Col>
                </Row>
            </div>
            
            
        </div>
    )
}

export default SignUp;
