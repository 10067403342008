import React from "react";
import logo from "../images/Hai-Ciso .png"
import dash from "../images/dash.png"
import EnquiryList from "../EnquiryList/EnquiryList";
import {Switch,Route} from  "react-router-dom";
import styles from "./Dashboard.module.css"
class DashBoard extends React.Component {

    state = {
        active_tab: "DASHBOARD" 
    }

    render() {
        console.log(this.state.active_tab);
        const { active_tab } = this.state
        return (<div className="container-fluid">
            <nav className="text-light d-flex p-2">
                <img  height="50px" alt="logo" src={logo}></img>
                <div style={{
                    marginLeft:"10%"
                }} className="d-flex">
                    <p className={`p-3 ${styles.home}`}>Home</p>
                    <p style={{color:"#4F5779"}} className="p-3">My Assessment</p>
                    <p style={{color:"#4F5779"}} className="p-3">My Projects</p>
                    <p style={{color:"#4F5779"}} className="p-3">My Quicklinks</p>
                    <p style={{color:"#4F5779"}} className="p-3">My Profile & Settings</p>
                    <p style={{color:"#4F5779"}} className="p-3 ml-5">Log off</p>
                </div>
            </nav>
            <div className="row">
                <div className="col-2">
                    <div>
                        <div class="input-group mb-3">
                            <input style={{
                                backgroundColor: "#01F9FD",
                                border: "none"
                            }} type="text" class="form-control" placeholder="Search" aria-label="Recipient's username" aria-describedby="basic-addon2"></input>
                            <div class="input-group-append">
                                <button class="btn btn-primary" type="button"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                </svg></button>
                            </div>
                        </div>
                    </div>
                    <small className="text" style={{color:"#01F1F9"}}>Team work & General settings</small>
                    <div onClick={()=>{
                        this.setState({ active_tab: "DASHBOARD" })
                        this.props.history.push("/dashboard")
                    }}  className={`p-2 text-left text-light mt-5 ${active_tab == "DASHBOARD" ? "active-button" : null } `} >DashBoard</div>
                    <div onClick={()=>{
                        this.setState({ active_tab: "SCHEDULE" })
                        this.props.history.push("/dashboard")
                    }} className={`p-2 text-left text-light mt-5 ${active_tab == "SCHEDULE" ? "active-button" : null }`} style={{
                        
                        borderRadius:"5px",
                        cursor:"pointer",
                    }}>Schedule</div>
                    <div onClick={()=>{
                        this.setState({ active_tab: "INTEGRATION" })
                        this.props.history.push("/dashboard")
                    }} className={`p-2 text-left text-light mt-5 ${active_tab == "INTEGRATION" ? "active-button" : null }`} style={{
                        borderRadius:"5px",
                        cursor:"pointer",
                    }}>Integration</div>
                    <div onClick={()=>{
                        this.setState({ active_tab: "REFERENCE" })
                        this.props.history.push("/dashboard")
                    }} className={`p-2 text-left text-light mt-5 ${active_tab == "REFERENCE" ? "active-button" : null }`} style={{
                        borderRadius:"5px",
                        cursor:"pointer",
                    }}>Reference & Learning</div>
                    <div onClick={()=>{
                        this.setState({ active_tab: "LOGS" })
                        this.props.history.push("/dashboard")
                    }} className={`p-2 text-left text-light mt-5 ${active_tab == "LOGS" ? "active-button" : null }`} style={{
                        borderRadius:"5px",
                        cursor:"pointer",
                    }}
                   >Logs</div>
                    <div  className={`p-2 text-left text-light mt-5 ${active_tab == "ENQUIRES" ? "active-button" : null }`} style={{
                        cursor:"pointer",
                        borderRadius:"5px"
                    }} onClick={()=>{
                        this.setState({ active_tab: "ENQUIRES" }, () => this.props.history.push("/dashboard/inquries")) 
                    }}>Enquiries</div>
                </div>
                <div style={{
                    backgroundColor: "#0B1A3F",
                    height: "90vh"
                }} className="col-10">
                     <div>
                         {/* <img className="mt-5 ml-4" src={dash} alt="dash"></img> */}
                            <Switch>
                             
                             <Route exact path="/dashboard/inquries" component={EnquiryList}></Route>
                             <Route exact path="/*" render={()=><img className="mt-5 ml-4" src={dash} alt="dash"></img>}></Route>
                            </Switch>
                     </div>
                </div>
            </div>
        </div>)

    }
}

export default DashBoard