import './App.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./components/login";
import Signup from "./components/signup";
import Landing from "./components/Landing/Landing";
import DashBoard from './components/Dashboard/DashBoard';
import Enquiry from './components/enquiry';

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route  path="/dashboard" component={DashBoard}/>
          <Route exact path="/login" component={Login} />
          <Route exact path="/signup" component={Signup} />
          <Route exact path="/enquiry" component={Enquiry} />
          <Route exact path="/" component={Landing}/>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
