import React from 'react';
import styles from './Enquiry.module.css';
import Logo from '../images/Hai-Ciso .png';
import LogoBorder from '../images/logo-border.svg';
import ddicon from '../images/dropdown-icon.svg';
import {Row,Col} from 'react-bootstrap';
import axios from 'axios';
import { propTypes } from 'react-bootstrap/esm/Image';
import { render } from '@testing-library/react';

class Enquiry extends React.Component
 {


    state = {
        companyname:"",
        companytype:"",
        country:"",
        enquiryDate:"",
        preftimetocall:"",
        spoccontactnum:"",
        spocemailid:"",
        spocname:"",
        state:"",
        compref:""
    }

    changeInputs = (key,value)=>{
        this.setState({
            [key]:value
        })
    }

    onSubmit = ()=>{
        axios.post("https://psp01.herokuapp.com/customer_enquiry",{
            "CommunicationPref": this.state.compref,
            "CompanyName": this.state.companyname,
            "CompanyType": this.state.companytype,
            "Country": this.state.country,
            "EnquiryDate": this.state.enquiryDate ,
            "PrefTimetoCall": "18:24:00",
            "SPOCContactNum": this.state.spoccontactnum,
            "SPOCEmailId": this.state.spocemailid,
            "SPOCName":this.state.spocname,
            "State":this.state.state
        }).then(res=>{
            this.props.history.push("/")
        }).catch(err=>{
            console.log(err)
        })
    }
     render(){
        return (
            <div className={styles.maindiv}>
                <div className={styles.maininner_div}>
                {/* <div class="row">
                        <div class="col-md-6 text-right">
                        <Link to="/login"><button className={styles.signin_btn}>Sign In</button></Link>
                        </div>
                        <div class="col-md-6 text-left">
                        <Link to="/signup"><button className={styles.signup_btn}>Sign Up</button></Link>
                        </div>
                    </div> */}
                    
                    <Row>
                        <Col md={6} className={styles.logo_div}>
                            <div className={styles.logo_section}>
                                <img alt="image" src={LogoBorder} className={styles.logo_border}/>
                                <img alt="image" src={Logo} className={styles.signin_logo}/>
                            </div>
                            
                        </Col>
                        <Col md={6} className={styles.form_div}>
                            <form onSubmit={(event)=>event.preventDefault()} className={styles.signin_form}>
                                
                                <div className={styles.ctype_div}>
                                        <img alt="image" src={ddicon} className={styles.input_icon}/>
                                        {/* <input type="text" className={styles.ctype_inputs} placeholder="Company type"/> */}
                                        <select onChange={(event)=>this.changeInputs("companytype",event.target.value)} className={styles.ctype_inputs}>
                                            <option value="Entity">Company Type</option>
                                            <option value="Entity">Entity</option>
                                        </select>
                                    </div>
                                <div   className={styles.cname_div}>
                                        {/* <img src={ddicon} className={styles.input_icon}/> */}
                                        <input  onChange={(event)=>this.changeInputs("companyname",event.target.value)} type="text" className={styles.cname_inputs} placeholder="Company name"/>
                                    </div>
                                <div className={styles.uname_div}>
                                    {/* <img src={usericon} className={styles.input_icon}/> */}
                                    <input  onChange={(event)=>this.changeInputs("spocname",event.target.value)} type="text" className={styles.uname_inputs} placeholder="Spoc name"/>
                                </div>
                                <div className={styles.mail_div}>
                                    {/* <img src={emailicon} className={styles.input_icon}/> */}
                                    <input  onChange={(event)=>this.changeInputs("spocemailid",event.target.value)} type="text" className={styles.email_inputs} placeholder="Spoc Email ID"/>
                                </div>
                                <div className={styles.pass_div}>
                                    {/* <img src={pwdicon} className={styles.input_icon}/> */}
                                    <input  onChange={(event)=>this.changeInputs("spoccontactnum",event.target.value)} type="text" className={styles.password_inputs} placeholder="Mobile"/>
                                </div>
                                <div className={styles.form_firrow}>
                                    <div className={styles.comm_div}>
                                        <img alt="image" src={ddicon} className={styles.input_icon}/>
                                        {/* <input type="text" className={styles.comm_inputs} placeholder="Communication"/> */}
                                        <select onChange={(event)=>this.changeInputs("compref",event.target.value)} className={styles.comm_inputs}>
                                            <option value="">Communication</option>
                                            <option value="Email">Email</option>
                                            <option value="Mobile">Mobile</option>
                                        </select>
                                    </div>
                                    <div className={styles.ttcall_div}>
                                        {/* <img src={ddicon} className={styles.input_icon}/> */}
                                        <input  onChange={(event)=>this.changeInputs("preftimetocall",event.target.value)} type="text" className={styles.ttcall_inputs} placeholder="Time to call"/>
                                    </div>
                                </div>
                                <div className={styles.form_firrow}>
                                    <div className={styles.country_div}>
                                        <img alt="image" src={ddicon} className={styles.input_icon}/>
                                        {/* <input type="text" className={styles.country_inputs} placeholder="Country"/> */}
                                        <select  onChange={(event)=>this.changeInputs("country",event.target.value)} className={styles.country_inputs}>
                                            <option value="">Country</option>
                                            <option value="India">India</option>
                                        </select>
                                    </div>
                                    <div className={styles.state_div}>
                                        <img  alt="image" src={ddicon} className={styles.input_icon}/>
                                        {/* <input type="text" className={styles.state_inputs} placeholder="State"/> */}
                                        <select  onChange={(event)=>this.changeInputs("state",event.target.value)} className={styles.state_inputs}>
                                            <option value="">State</option>
                                            <option value="Andhra Pradesh">Andhra Pradesh</option>
                                            <option value="Tamil Nadu">Tamil Nadu</option>
                                        </select>
                                    </div>
                                </div>
                                <hr className={styles.hrline}/>
                                <div>
                                    <button onClick={this.onSubmit} className={styles.submit_btn}>Enquiry</button>
                                </div>
                            </form>
                            
                        </Col>
                    </Row>
                </div>
                
                
            </div>
        )
     }
}

export default Enquiry;
