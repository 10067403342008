import React from "react";
import axios from "axios";
import Modal from "../SubComponents/Model";
import BackDrop from "../BackDrop/Backdrop";

class EnquiryList extends React.Component{
    state = {
        data:[],
        data_index:0,
        show:false
    }
    
    componentDidMount() {
    
        axios.get("https://psp01.herokuapp.com/customer_enquiry",{email:this.state.email,password:this.state.password}).then(res=>{
            console.log(res.data)
            this.setState({
                data:res.data.data,
            })
          })
    }

    closeModal = ()=>{
        this.setState({
            show:false
        })
    }

    render() {
      return(
        <div>
            <div style={{
                        backgroundColor:"#0087A5",
                        cursor:"pointer"
                    }} className="text-light mb-2 p-3 mt-3 d-flex align-items-center justify-content-between" >
                         <b className="d-block w-50">#Id</b>
                        <b className="d-block w-50">COMPANY NAME</b>
                        <b className="d-block w-50">COMPANY TYPE</b>
                        <b className="d-block w-50">ENQUIRY DATE</b>
                        <b className="d-block w-10">ACTION</b>
                    </div>
             {this.state.show? <BackDrop close={this.closeModal}><Modal closeModal={this.closeModal} data={this.state.data[this.state.data_index]} /></BackDrop>:null}
            {
                this.state.data.map((e,index)=>{
                    return (<div style={{
                        cursor:"pointer",
                        background: "rgba(0, 135, 165, 0.1)",
                        border: "1px solid #0087A5"
                    }} className="text-light mb-2 p-2 mt-3 d-flex align-items-center justify-content-between" key={index}>
                          <p className="d-block w-50">{index}</p>
                        <b className="d-block w-50">{e.CompanyName}</b>
                        <b className="d-block w-50">{e.CompanyType}</b>
                        <small className="d-block w-50">{e.EnquiryDate}</small>
                        <button className="d-block w-50" onClick={()=>{
                            this.setState({
                                show:true,
                                data_index:index
                            })
                        }} class="btn btn-sm btn-primary">View</button>
                    </div>)
                })
            }
        </div>)
    }
}


export default EnquiryList;