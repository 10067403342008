import axios from "axios";
import React from "react";
import styles from "./Model.module.css";


class Modal extends React.Component{

//     CommunicationPref: "English"
// ​​​
// CompanyName: "Tesla"
// ​​​
// CompanyType: "Entity"
// ​​​
// Country: "India"
// ​​​
// CreatedAt: "2021-06-07T10:27:24.000Z"
// ​​​
// EnquiryDate: "2021-06-08T00:00:00.000Z"
// ​​​
// EnquiryId: 8
// ​​​
// ModifiedAt: "2021-06-07T10:27:24.000Z"
// ​​​
// PrefTimetoCall: "18:24:00"
// ​​​
// SPOCContactNum: "8448484844"
// ​​​
// SPOCEmailId: "spoc@tesla.com"
// ​​​
// SPOCName: "teslaspoc"
// ​​​
// State: "tamilnadu"
    addEx = ()=>{
         axios.post("https://psp01.herokuapp.com/customer_enquiry/addExec",{
            "id": this.props.data["EnquiryId"],
            "PSPAcctExecId": 1
        }).then((res)=>{
            console.log(res)
            this.props.closeModal()
        }).catch(err=>{
            console.log(err)
        })
    }

    render() {
        return (
            <div className={styles.modal__container}>
                 <div>
                 <div style={{
                    cursor:"pointer"
                }} className="d-flex justify-content-end mb-3">
              </div>
                <div className="d-flex justify-content-between">
                    <p class="d-block w-50">Company name</p>
                    <small class="d-block w-50">{this.props.data["CompanyName"]}</small>
                </div>
                <div className="d-flex justify-content-between">
                    <p class="d-block w-50">Company Type</p>
                    <small class="d-block w-50">{this.props.data["CompanyType"]}</small>
                </div>
                <div className="d-flex justify-content-between">
                    <p class="d-block w-50">Country</p>
                    <small class="d-block w-50">{this.props.data["Country"]}</small>
                </div>
                <div className="d-flex justify-content-between">
                    <p class="d-block w-50">EnquiryDate</p>
                    <small class="d-block w-50">{this.props.data["EnquiryDate"]}</small>
                </div>
                <div className="d-flex justify-content-between">
                    <p class="d-block w-50">PrefTimetoCall</p>
                    <small class="d-block w-50">{this.props.data["PrefTimetoCall"]}</small>
                </div>
                <div className="d-flex justify-content-between">
                    <p class="d-block w-50">SPOCContactNum</p>
                    <small class="d-block w-50">{this.props.data["SPOCContactNum"]}</small>
                </div>
                <div className="d-flex justify-content-between">
                    <p class="d-block w-50">SPOCEmailId</p>
                    <small class="d-block w-50">{this.props.data["SPOCEmailId"]}</small>
                </div>
                <div className="d-flex justify-content-between">
                    <p class="d-block w-50">SPOCName</p>
                    <small class="d-block w-50">{this.props.data["SPOCName"]}</small>
                </div>
                <div className="d-flex justify-content-between">
                    <p class="d-block w-50">State</p>
                    <small class="d-block w-50">{this.props.data["State"]}</small>
                </div>
                <hr class="bg-light"/>
                <div className="d-flex justify-content-between">
                    <p class="d-block w-50">Assign-To</p>
               
                        <select className="bg-primary p-1 d-block w-50 text-light" style={{border:"none"}}>
                            <option>sam</option>
                            <option>will</option>
                        </select>
                 
                </div>
                <button onClick={()=>this.addEx()} className="btn btn-block mt-2 btn-danger">Submit</button>
                 </div>
                
            </div>
        )
    }
}


export default Modal;